import { useEffect, useRef, type FC } from "preact/compat";
import { register, type SwiperContainer } from "swiper/element/bundle";

import { activeProduct } from "../models/products.ts";

import "./WalletImagesSlider.scss";
import type { GetImageResult } from "astro";
import type { SwiperOptions } from "swiper/types";
import { useComputed, useSignalEffect } from "@preact/signals";

const params: SwiperOptions = {
  injectStyles: [
    `
    .swiper-pagination-bullets {
      --swiper-pagination-bottom: 16px;
      padding-inline: var(--page-padding-inline);
    }

    .swiper-pagination-bullet {
      visibility: hidden;
      width: calc((100vw - 2 * var(--page-padding-inline) - 16px) / 3);
      max-width: 100px;
      height: 2px;
      background: rgb(77, 77, 77);
      border-radius: 0;
      transform: scale(1) !important;
      opacity: 1;

      @media screen and (min-width: 768px) {
        max-width: 106px;
        width: 106px;
      }
    }

    .swiper-pagination-bullet-active {
      background: #fff;
    }

    .swiper-pagination-bullet-active-next, .swiper-pagination-bullet-active-prev, swiper-pagination-bullet-active, .swiper-pagination-bullet-active-main {
      visibility: visible;
    }
    `,
  ],
  // pagination: {
  //   clickable: true,
  //   dynamicBullets: true,
  // },
  autoplay: {
    delay: 2700,
    disableOnInteraction: false,
  },
  loop: false,
};

type Props = {
  images: Record<string, { images: GetImageResult[]; preview: GetImageResult }>;
};
export const WalletImagesSlider: FC<Props> = ({ images }) => {
  const swiperRef = useRef<SwiperContainer | null>(null);

  const slides = useComputed(() =>
    activeProduct.value?.id ? images[activeProduct.value?.id].images : [],
  );

  useEffect(() => {
    register();
    Object.assign(swiperRef.current!, params);
    swiperRef.current!.initialize();
  }, []);

  useSignalEffect(() => {
    const loopNeeded = slides.value.length > 1; // subsription to changes in slides
    Object.assign(swiperRef.current!, { loop: loopNeeded });

    swiperRef.current?.swiper.update(); // if slides are chaned we need to notify swiper
  });

  return (
    <div class="slider__wrapper">
      <swiper-container
        id="wallet-images-slider"
        class="slider__container"
        init="false"
        ref={swiperRef}
      >
        {slides.value.map((image) => (
          <swiper-slide key={image.src} class="slider__item">
            <img
              src={image.src}
              alt={activeProduct.value?.title}
              loading="lazy"
              decoding="async"
            />
          </swiper-slide>
        ))}
      </swiper-container>
    </div>
  );
};
