import classNames from "classnames";
import {
  isLimited,
  isStealth,
  isUSA,
  limitedGradient,
} from "../models/products.ts";

import s from "./LimitedFeatures.module.scss";

interface Props {
  limitedCaptionText: string;
  features: Record<string, string>;
  usaFeatures: Record<string, string>;
}

export const LimitedFeatures = ({
  limitedCaptionText,
  features,
  usaFeatures,
}: Props) => {
  return isLimited.value ? (
    <div className={s.limited}>
      {isStealth.value ? (
        <ul className={s.limited__features}>
          <li className={s.limited__feature}>{features["without-logo"]}</li>
          <li className={s.limited__feature}>{features["anonymity"]}</li>
        </ul>
      ) : null}
      {isUSA.value ? (
        <ul className={s.limited__features}>
          <li className={s.limited__feature}>{usaFeatures["front-side"]}</li>
          <li className={s.limited__feature}>{usaFeatures["back-side"]}</li>
        </ul>
      ) : null}
      <div className={s["limited__caption-wrapper"]}>
        <span
          className={classNames(s.limited__caption)}
          style={{
            backgroundImage: limitedGradient.value,
          }}
        >
          {limitedCaptionText}
        </span>
      </div>
    </div>
  ) : null;
};
